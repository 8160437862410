const config = {
  asma: {
    name: 'Asma',
    color: '#448aff',
    colorSecondary: 'rgb(85, 159, 255)',
    colorTertiary: 'rgba(56, 87, 138, 0.94)',
    fontcolor: 'white'
  },
  preanestesas: {
    name:'Preanestesas',
    color: '#448aff',
    colorSecondary: 'rgb(85, 159, 255)',
    colorTertiary: 'rgba(56, 87, 138, 0.94)',
    fontcolor: 'white',
    systemUser: 'cronicare'
  },
  smartmigraine: {
    name:'Smart Migraine',
    color: '#448aff',
    colorSecondary: 'rgb(85, 159, 255)',
    colorTertiary: 'rgba(56, 87, 138, 0.94)',
    fontcolor: 'white',
    systemUser: 'smartmigraine'
  },
  appictus: {
    name:'App Ictus',
    color: '#448aff',
    colorSecondary: 'rgb(85, 159, 255)',
    colorTertiary: 'rgba(56, 87, 138, 0.94)',
    fontcolor: 'white',
    systemUser: 'app_ictus'
  },
  cronicare: {
    name:'Cronicare',
    color: '#3c9994',
    colorSecondary: '#73c8c3ff',
    colorTertiary: 'rgba(56, 87, 138, 0.94)',
    fontcolor: 'white',
    systemUser: 'us_cronicare'
  },
  salpro: {
    name:'Salpro',
    color: '#448aff',
    colorSecondary: 'rgb(85, 159, 255)',
    colorTertiary: 'rgba(56, 87, 138, 0.94)',
    fontcolor: 'white',
    systemUser: 'salpro'
  },
  recardio: {
    name:'Recardio',
    color: '#499661',
    colorSecondary: '#64a352',
    colorTertiary: 'rgba(56, 87, 138, 0.94)',
    fontcolor: 'white',
    systemUser: 'Recardio'
  },
  rinobase: {
    name:'RinoBase',
    color: '#499661',
    colorSecondary: '#64a352',
    colorTertiary: 'rgba(56, 87, 138, 0.94)',
    fontcolor: 'white',
    systemUser: 'Rinobase'
  },
  psapp: {
    name:'ACPROAND',
    color: '#448aff',
    colorSecondary: 'rgb(85, 159, 255)',
    colorTertiary: 'rgba(56, 87, 138, 0.94)',
    fontcolor: 'white',
    systemUser: 'psapp',
  },
  bienvenida: {
    name:'HUVM',
    color: 'rgb(0, 134, 68)',
    colorSecondary: '#66ad5500',
    colorTertiary: 'rgba(56, 87, 138, 0.94)',
    fontcolor: 'white',
    systemUser: 'APP_BIENVENIDA',
    noAuth: true
  },
  welcome: {
    name:'HUVM',
    color: 'rgb(0, 134, 68)',
    colorSecondary: '#66ad5500',
    colorTertiary: 'rgba(56, 87, 138, 0.94)',
    fontcolor: 'white',
    systemUser: 'welcome',
    internalAuth: true
  },
  tep: {
    name:'TEP',
    color: 'rgb(0, 134, 68)',
    colorSecondary: '#66ad5500',
    colorTertiary: 'rgba(56, 87, 138, 0.94)',
    fontcolor: 'white',
    systemUser: 'tep',
  },
  geoasma: {
    name:'Geoasma',
    color: '#448aff',
    colorSecondary: 'rgb(85, 159, 255)',
    colorTertiary: 'rgba(56, 87, 138, 0.94)',
    fontcolor: 'white',
    systemUser: 'geoasma',
  },
  misiones: {
    name:'Misiones',
    color: '#448aff',
    colorSecondary: 'rgb(85, 159, 255)',
    colorTertiary: 'rgba(56, 87, 138, 0.94)',
    fontcolor: 'white',
    systemUser: 'proyectomisiones',
  },
  epid: {
    name:'Epid',
    color: '#448aff',
    colorSecondary: 'rgb(85, 159, 255)',
    colorTertiary: 'rgba(56, 87, 138, 0.94)',
    fontcolor: 'white',
    systemUser: 'epid',
  },
  reuma: {
    name:'Reuma',
    color: '#448aff',
    colorSecondary: 'rgb(85, 159, 255)',
    colorTertiary: 'rgba(56, 87, 138, 0.94)',
    fontcolor: 'white',
    systemUser: 'reuma',
  },
  reuma2: {
    name:'Reuma',
    color: '#448aff',
    colorSecondary: 'rgb(85, 159, 255)',
    colorTertiary: 'rgba(56, 87, 138, 0.94)',
    fontcolor: 'white',
    systemUser: 'app_reuma',
  },
  default: {
    name:'Cronicare',
    color: '#448aff',
    colorSecondary: '#66ad5500',
    colorTertiary: 'rgba(56, 87, 138, 0.94)',
    fontcolor: 'white'
  },
   apiUrl: "https://www.sspa.juntadeandalucia.es/servicioandaluzdesalud/hhuuvr/esaludpre/MirthOperaciones/ITCBIO"
  // apiUrl: "http://10.232.0.226:8083/servicioandaluzdesalud/hhuuvr/esaludpre/MirthOperaciones/ITCBIO"

};

export default config;